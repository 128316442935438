.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f0f0;
}

.chat-container {
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
}

.brand-logo {

    max-width: 200px; /* Set a max-width to keep the logo responsive */
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 0px; /* Space between logo and subtitle */
}
