.chat-message {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.chat-message.user {
  justify-content: flex-end;
}

.chat-message.bot {
  justify-content: left;
}

.chat-message-content {
  padding: 10px;
  border-radius: 10px;
  /* max-width: 60%; */
  word-wrap: break-word;
}

.chat-message-content.user {
  margin-right: 20px;
  background-color: #f0f0f0;
  text-align: left;
}

.chat-message-content.assistant {
  background-color: white;
  text-align: left;
}

.message-container {
  display: flex;
  align-items: flex-start; 
}

.ball-container {
  margin-top: 10px;
  margin-right: 20px;
}

.ball {
  width: 40px;
  display: block;
}


@media screen and (max-width: 768px) {
  .message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }
  
  .chat-message-content {
    max-width: 100%;
  }

  .chat-message.assistant {
    padding: 20px;
  }

  .ball-container {
    margin-top: 20px;
    margin-right: 10px;
  }
  
  .ball {
    width: 30px;
    display: block;
  }
}