.admin-login {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-login input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.admin-login button {
    width: 100%;
    padding: 10px;
    background: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-panel {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.prompts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px 0;
}

.prompt-box {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.prompt-box textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.save-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 12px;
    background: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-button:hover {
    background: #333;
} 