/* General Styles for Chat Window */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-left: 1px solid #ccc;
  padding: 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
}

/* Loading Container */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rotating-ball {
  width: 40px;
  height: 40px;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

.loading-text {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}

/* Messages Container */
.messages {
  flex: 1;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .chat-window {
    padding: 0.5rem;
  }
}
