.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    background-color: black;
    color: white;
    padding: 15px 30px; 
    font-size: 12px;
}

.toggle-sidebar-button {
    background-color: transparent; 
    border: none; 
    padding: 0; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; 
    height: 40px; 
    margin-right: 10px;
}

.toggle-sidebar-button img {
    max-width: 100%; 
    max-height: 100%; 
    display: block; 
}

.chat-header-logo {
    width: 320px;
    height: auto;

}

.new-chat-button {
    background-color: transparent; 
    border: none; 
    padding: 0; 
    cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; 
    height: 40px; 
}

.new-chat-icon {
    width: 100%; 
    height: auto; 
}

.selected-chat {
    background-color: #f0f0f0; /* Highlight background color */
    color: #707070; /* Text color */
}

/*terms of use and privacy policy*/
/* Modal Styling */
.terms-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.terms-modal-content {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto; /* Allow scrolling if the content is too long */
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.modal-text {
    text-align: left; /* Align text to the left */
    color: #000; /* Set text color */
    padding: 10px; /* Add some padding for better readability */
    margin: 0; /* Ensure no extra margin */
    white-space: normal; /* Prevent extra spaces and line breaks from being preserved */
    line-height: 1.5; /* Improve line spacing for readability */
    font-size: 14px; /* Set a readable font size */
    overflow-wrap: break-word; /* Break long words if necessary */
}

/* Styling for the dropdown menu */
.chat-history-title {
    font-size: 18px;
    padding: 15px;
    cursor: not-allowed;
    font-weight: bold;
    color: black; /* Ensure title text is black */
}

.chat-history-list {
    max-height: 150px; /* Adjust this value based on your design needs */
    overflow-y: auto; /* Enables scrollbar when content overflows */
    overflow-x: hidden; /* Hides horizontal scrollbar */
}


/* Dropdown menu container */
.dropdown-menu {
    position: absolute;
    top: 68px; /* Adjust based on your header height */
    left: 10px; /* Default for larger screens */
    width: 250px; /* Default width */
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-top: 2px solid black;
    padding: 0;
    transition: all 0.3s ease; /* Smooth transitions */
}

.dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Dropdown menu list items */
.dropdown-menu li {
    padding: 12px 15px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    text-align: left;
    border-bottom: none; /* Removed the bottom border */
}

/* Add hover effect for non-disabled items */
.dropdown-menu li:hover:not(.disabled) {
    background-color: #f0f0f0;
    color: black;
}

/* Styling for disabled items */
.disabled {
    color: #d7d7d7 !important; /* Ensure disabled text is gray */
    pointer-events: none; /* Prevent interaction */
    cursor: not-allowed;
}

/* Remove hover effect for disabled items */
.disabled:hover {
    background-color: transparent; /* No background change on hover */
    color: #d7d7d7; /* Keep the gray color on hover */
}


/* Removed unnecessary separators */
.dropdown-menu hr {
    display: none; /* Hide horizontal lines */
}

/* Adjust the section divider where necessary */
.dropdown-menu .section-divider {
    margin: 10px 0; /* Optional: Adjust spacing */
    border-top: 2px solid black; /* Bold black line for section separation */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dropdown-menu {
        top: 58px;
        width: 200px; /* Reduced width for mobile view */
        left: 10px; /* Adjust the left margin for mobile screens */
    }

    .dropdown-menu li {
        padding: 12px;
        font-size: 14px; /* Adjusted font size for smaller screens */
    }
    .chat-header {
        justify-content: space-between; 
        padding: 10px 20px; 
    }

    .toggle-sidebar-button, .new-chat-button {
        width: 40px; 
        height: 40px; 
    }

    .chat-header-logo {
        width: 240px; 
    }
}

@media (max-width: 480px) {
    .dropdown-menu {
        width: 200px; /* Full width for mobile screens */
        left: 10px; /* Adjust left position for mobile view */
    }

    .dropdown-menu li {
        padding: 10px;
        font-size: 12px; /* Smaller font size for very small screens */
    }
}