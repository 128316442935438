/*Chat Prompts*/
.chat-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border-top: none;
    max-width: 100%;
    box-sizing: border-box;
}


.chat-prompts {
    display: flex;
    flex-wrap: wrap;  
    justify-content: center; 
    margin-bottom: 10px;
    padding: 5px;
    gap: 10px; 
    width: 100%; 
}

.chat-prompt-button {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    white-space: normal;
    word-wrap: break-word; 
    flex-grow: 1; 
    flex-shrink: 1; 
    flex-basis: 15%; 
    max-width: 180px; 
    min-width: 120px; 
}

.chat-prompt-button:hover {
    background-color: #e6e6e6;
}

.chat-input-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.chat-input-field {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    font-size: 16px;
}

.chat-send-button {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-send-button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 768px) {
    .chat-prompts {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-gap: 10px;
        justify-items: center;
        width: 100%;
    }

    .chat-prompt-button {
        width: 100%;
        max-width: none;
        min-width: none;
        margin: 5px 0; 
    }
}

/* End ChatPrompt */