body {
  font-family: Arial, sans-serif;
}

.contact-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #3570D6;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="text"],
input[type="email"] {
  height: 40px;
}

textarea {
  height: 100px;
  resize: none;
}

small {
  display: block;
  margin-top: 5px;
  font-size: 0.9rem;
  color: #666;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #3570D6;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #2A58AA;
}

/* Styling for the Thank You message */
.thank-you-message {
  text-align: center;
  color: #000; 
}

.thank-you-message h2 {
  font-size: 1.5rem;
  color: #000; 
}

.thank-you-message p {
  font-size: 1rem;
  color: #000; 
}

@media (max-width: 600px) {
  .contact-container {
    padding: 15px;
  }

  input,
  textarea {
    font-size: 0.9rem;
    padding: 8px;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 10px;
  }
}