.load-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh; /* Full viewport height */
  margin: 0; /* Remove any margin */
}


.rotate-ball {
  width: 100px;  /* Adjust size as needed */
  height: 100px;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}